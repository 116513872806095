import { Andaman, Andaman1, Andaman2, Assam, Assam1, Assam2, Assam3, Assam4, Himachal, Himachal1, Himachal2, Himachal3, Himachal4, Himachal5, JK, JK1, JK2, JK3, JK4, Karnataka, Karnataka1, Karnataka2, Karnataka3, Karnataka4, Kerala, Kerala1, Kerala2, Kerala3, Kerala4, Kerala5, Kerala6, Kerala7, Kerala8, Kerala9, Meghalaya, Meghalaya1, Meghalaya2, Punjab, Punjab1, Punjab2, Rajasthan, Rajasthan1, Rajasthan2, Rajasthan3, Rajasthan4, Rajasthan5, Rajasthan6, Sikkim, Sikkim1, Sikkim2, Uttarakhand, Uttarakhand1, Uttarakhand2, Uttarakhand3, Uttarakhand4, Uttarakhand5, Uttarakhand6, Uttarakhand7, Bhutan, Bhutan1, Bhutan2, Gujarat, Gujarat1, Gujarat2 } from "../Images";

const StateContent = {
  andaman_and_nicobar: {
    title: "Andaman and Nicobar",
    subtitle: ["Discover Andaman and Nicobar with us"],
    videos: [
      "https://www.youtube.com/embed/KVxo0qq7ukU",
      "https://www.youtube.com/embed/N241DmVLdmk"
    ],
    cities: ["Neil Island", "Havelock", "Port Blair"],
    image: [Andaman],
    imgs: [
      Andaman1,
      Andaman2,
    ],
  },
  assam: {
    title: "Assam",
    subtitle: ["Discover Assam with us"],
    videos: [
      "https://www.youtube.com/embed/zb_EiqadMw0",
    ],
    cities: ["Kaziranga National Park"],
    image: [Assam],
    imgs: [
      Assam3,
    ],
  },

  bhutan: {
    title: "Bhutan",
    subtitle: ["Discover Bhutan with us"],
    videos: [
      "https://www.youtube.com/embed/XWcXAFFg5uQ",
      "https://www.youtube.com/embed/xlY_M3It_Ss",
    ],
    cities: ["Thimphu", "Punakha", "Paro"],
    image: [Bhutan],
    imgs: [
      Bhutan1,
      Bhutan2,
    ],
  },
  gujarat: {
    title: "Gujarat",
    subtitle: ["Discover Gujarat with us"],
    videos: [
      "https://www.youtube.com/embed/VnR28uLu8IQ",
      "https://www.youtube.com/embed/GmT0MKlUbYA",
    ],
    cities: ["Rann Utsav", "Statue of Unity"],
    image: [Gujarat],
    imgs: [
      Gujarat1,
      Gujarat2
    ],
  },
  himachal_pradesh: {
    title: "Himachal Pradesh",
    subtitle: ["Discover Himachal Pradesh with us"],
    videos: [
      "https://www.youtube.com/embed/fjFjeXYtMwk",
      "https://www.youtube.com/embed/6k3yKfJIZd0",
      "https://www.youtube.com/embed/mQNcAoPp9b4",
      "https://www.youtube.com/embed/yDQsNDZtzck",
      "https://www.youtube.com/embed/hrvdL8ell7M",

    ],
    cities: [
      "Dalhousie",
      "Dharamshala",
      "Spiti",
      "Manali",
      "Shimla",
      "Chail",
      "Chandigarh",
    ],
    image: [Himachal2],
    imgs: [
      Himachal,
      Himachal1,
      Himachal3,
      Himachal4,
      Himachal5,
    ],
  },
  jammu_kashmir: {
    title: "Jammu & Kashmir",
    subtitle: ["Discover Jammu And Kashmir with us"],
    videos: [
      "https://www.youtube.com/embed/ujmkx1uyeZI",
      "https://www.youtube.com/embed/ciLshrt-CeQ",
      "https://www.youtube.com/embed/yJ3E1U3WJ0U",
      "https://www.youtube.com/embed/9I-QC3XPSfk"
    ],
    cities: ["Srinagar", "Sonmarg", "Gulmarg", "Pahalgam"],
    image: [JK],
    imgs: [
      JK1,
      JK2,
      JK3,
      JK4,
    ],
  },
  karnataka: {
    title: "Karnataka",
    subtitle: ["Discover Karnataka with us"],
    videos: [
      "https://www.youtube.com/embed/VIDEO_ID_31",
      "https://www.youtube.com/embed/VIDEO_ID_32",
    ],
    cities: ["Coorg", "Bandipur"],
    image: [Karnataka],
    imgs: [
      Karnataka1,
      Karnataka2,
      Karnataka3,
      Karnataka4,
    ],
  },
  kerala: {
    title: "Kerala",
    subtitle: ["Discover Kerala with us"],
    videos: [
      "https://www.youtube.com/embed/pepMT-SaKeY",
      "https://www.youtube.com/embed/MmRrHcRl_Do",
      "https://www.youtube.com/embed/yr3BodsdvFI",
      "https://www.youtube.com/embed/miomq1prWhw",
      "https://www.youtube.com/embed/LyOqvII0uxE",
      "https://www.youtube.com/embed/0BidkipwjCU",
      "https://www.youtube.com/embed/PDWXKroj18E",
      "https://www.youtube.com/embed/Srq0lIQi2is",
      "https://www.youtube.com/embed/BTlLH7LhqcA"
    ],
    cities: [
      "Wayanad",
      "Kochi",
      "Munnar",
      "Thekkady",
      "Alleppey",
      "Kumarakom",
    ],
    image: [Kerala],
    imgs: [
      Kerala1,
      Kerala2,
      Kerala3,
      Kerala9,
      Kerala5,
      Kerala6,
      Kerala4,
      Kerala7,
      Kerala8,
    ],
  },
  meghalaya: {
    title: "Meghalaya",
    subtitle: ["Discover Meghalaya with us"],
    videos: [
      "https://www.youtube.com/embed/ybLXAUO2EBQ",
      "https://www.youtube.com/embed/uRfV6OrfgaM",
    ],
    cities: ["Shillong", "Cherrapunji", "Guwahati"],
    image: [Meghalaya],
    imgs: [
      Meghalaya1,
      Meghalaya2,
    ],
  },
  punjab: {
    title: "Punjab",
    subtitle: ["Discover Pubjab with us"],
    videos: [
      "https://www.youtube.com/embed/hrvdL8ell7M",
      "https://www.youtube.com/embed/mQNcAoPp9b4"
    ],
    cities: ["Wagah Border", "Amritsar"],
    image: [Punjab],
    imgs: [
      Punjab1,
      Punjab2,
    ],
  },
  rajasthan: {
    title: "Rajasthan",
    subtitle: ["Discover Rajasthank with us"],
    videos: [
      "https://www.youtube.com/embed/X_Y0It4Qv14",
      "https://www.youtube.com/embed/X8P6d2g-YZM",
      "https://www.youtube.com/embed/SpgTD5m5aUc",
      "https://www.youtube.com/embed/4fZNAtrhLtQ",
      "https://www.youtube.com/embed/mkYVD-e2JN8",
      "https://www.youtube.com/embed/7al6jcRHagw"
    ],
    cities: ["Jaipur", "Udaipur", "Jawai Bandh", "Kumbhalgarh",],
    image: [Rajasthan1],
    imgs: [
      Rajasthan2,
      Rajasthan,
      Rajasthan3,
      Rajasthan4,
      Rajasthan5,
      Rajasthan6,
    ],
  },
  sikkim: {
    title: "Sikkim",
    subtitle: ["Discover Sikkim With Us"],
    videos: [
      "https://www.youtube.com/embed/7Tu3rBRR8DA",
      "https://www.youtube.com/embed/ciBfyp6a6qw",
    ],
    cities: ["Nathula Pass", "Lachung", "Pelling", "Gangtok",],
    image: [Sikkim],
    imgs: [
      Sikkim1,
      Sikkim2,
    ],
  },
  uttarakhand: {
    title: "Uttarakhand",
    subtitle: ["Discover Uttarakhand With Us"],
    videos: [
      "https://www.youtube.com/embed/PGxnLulUhoM",
      "https://www.youtube.com/embed/_KhIMiadEPs",
      "https://www.youtube.com/embed/5O3pZ24CGsk",
      "https://www.youtube.com/embed/y_kDP2DgJQw",
      "https://www.youtube.com/embed/XcoWvAmgfJc",
      "https://www.youtube.com/embed/O4O31EGSPTg",
      "https://www.youtube.com/embed/PS4o7DK4ggk"
    ],
    cities: ["Kausani", "Bhimtal", "Jim Corbett", "Delhi"],
    image: [Uttarakhand1],
    imgs: [
      Uttarakhand,
      Uttarakhand3,
      Uttarakhand2,
      Uttarakhand4,
      Uttarakhand5,
      Uttarakhand6,
      Uttarakhand7,
    ],
  },
  west_bengal: {
    title: "West Bengal",
    subtitle: ["Discover West Bengal With Us"],
    videos: [
      "https://www.youtube.com/embed/VIDEO_ID_67",
      "https://www.youtube.com/embed/VIDEO_ID_68",
    ],
    cities: ["Darjeeling"],
    image: ["images/bengal1.jpg"],
    imgs: [
      "images/bengal2.jpg",
      "images/bengal3.jpg",
      "images/bengal4.jpg",
      "images/bengal5.jpg",
    ],
  },
}
export default StateContent;