import React, { useRef, useState, useEffect } from 'react'
import { motion, useInView } from 'framer-motion';
import '../styling/App.css';
import '../styling/Index.css';
import '../styling/Style.css';
import { Header, Arrow, Women, Story, Rajasthan } from '../Images'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom';
import useDocumentTitle from './UseDocumentTitle';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const [currentIndex, setCurrentIndex] = useState(0);
  useDocumentTitle('Soul Trails | About ');


  const testimonials = [
    {
      image: Women,
      heading: t('About.heading'),
      description: t('About.heading-text'),
    },
    {
      image: Story,
      heading: t('About.heading2'),
      description: t('About.heading-text2'),
    },
    {
      image: Story,
      heading: t('About.heading3'),
      description: t('About.heading3'),
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: '-40%' });

  const slideUp = {
    hidden: {
      y: '10vh',
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.9,
        ease: [0.42, 0, 0.58, 1],
      },
    },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <>
      <Navbar />
      <div className="page-wrapper">
        <section className="section-hero">
          <header className="uui-section_heroheader03">
            <div className="uui-page-padding">
              <div className="uui-container-large">
                <div className="uui-padding-vertical-xhuge" variants={staggerContainer} animate="visible" initial="hidden">
                  <div className="ani-slide-in-parent">
                    <div className="w-layout-grid uui-heroheader03_component">
                      <div className="position-relative z-index-2" variants={slideUp}>
                        <div className="ani-slide-in-on-parent is-child-01">
                          <div className="uui-heroheader03_content">
                            <h1 className="web-heading-h1 web-heading-paragraph">
                            {t('About.header')}
                            </h1>
                            <div className="uui-max-width-large">
                              <div className="web-body-medium text-style-muted web-body-paragraph">
                              {t('About.header-section')}
                              </div>
                            </div>
                            <div className="herosection-btn-wrapper">
                              <div className="uui-button-wrapper">
                                <Link
                                  to="/packages"
                                  className="uui-button is-button-large w-inline-block"
                                >
                                  <div className="web-body-regular">
                                  {t('Home.button1')}
                                  </div>
                                  <div className="icon-embed-xsmall w-embed">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="100%"
                                      height="100%"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      preserveAspectRatio="xMidYMid meet"
                                      aria-hidden="true"
                                      role="img"
                                    >
                                      <path
                                        d="M7 17L17 7M17 7H7M17 7V17"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ani-slide-in-on-parent is-child-04" variants={slideUp}>
                        <div className="uui-heroheader03_image-wrapper">
                          <img
                            src={Header}

                            alt="Header image"
                            className="uui-heroheader03_image"
                          />
                          <img
                            src={Arrow}

                            alt="Arrow"
                            className="uui-heroheader03_arrow-image hide-tablet"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </section>
        <section className="section-story">
          <section className="uui-section_testimonial12">
            <div className="uui-page-padding">
              <div className="uui-container-large">
                <div className="uui-padding-vertical-xhuge">
                  <div className="ani-slide-in-parent">
                    <div className="ani-slide-in-on-parent is-child-02">
                      <div
                        data-delay="4000"
                        data-animation="cross"
                        className="uui-testimonial12_component-2 w-slider"
                        data-autoplay="false"
                        data-easing="ease"
                        data-hide-arrows="false"
                        data-disable-swipe="false"
                        data-autoplay-limit="0"
                        data-nav-spacing="6"
                        data-duration="500"
                        data-infinite="true"
                      >
                        <div className="uui-testimonial12_mask-2 w-slider-mask">
                          {testimonials.map((testimonial, index) => (
                            <div
                              key={index}
                              className={`uui-testimonial12_slide-2 w-slide ${currentIndex === index ? "active" : ""
                                }`}
                              style={{
                                display: currentIndex === index ? "block" : "none",
                              }}
                            >
                              <div className="w-layout-grid uui-testimonial12_content-2">
                                <a
                                  href="#"
                                  data-w-id="49c4c263-e188-226c-6f1d-0b00bcab187b"
                                  className="uui-testimonial12_lightbox-2 w-inline-block w-lightbox"
                                >
                                  <img
                                    src={testimonial.image}
                                    loading="lazy"
                                    sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, 44vw"
                                    alt="Testimonial"
                                    className="uui-testimonial12_lightbox-image-2"
                                  />
                                </a>
                                <div
                                  id="w-node-_49c4c263-e188-226c-6f1d-0b00bcab187f-48705cae"
                                  className="uui-testimonial12_content-right-2"
                                >
                                  <h2 className="web-heading-h2">{testimonial.heading}</h2>
                                  <div className="web-body-regular">{testimonial.description}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div
                          className="uui-testimonial12_arrow-2 left w-slider-arrow-left"
                          onClick={prevSlide}
                        >
                          <div className="uui-button-icon w-embed">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 12H5M5 12L12 19M5 12L12 5"
                                stroke="CurrentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className="uui-testimonial12_arrow-2 w-slider-arrow-right"
                          onClick={nextSlide}
                        >
                          <div className="uui-button-icon w-embed">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 12H19M19 12L12 5M19 12L12 19"
                                stroke="CurrentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="uui-testimonial12_slide-nav-2 w-slider-nav w-round"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="section-team" />
      </div>
      <Footer />
      <div className="modal-wrapper">
        <div className="modal-form w-form">
          <form
            id="wf-form-Testimonial-Form"
            name="wf-form-Testimonial-Form"
            data-name="Testimonial Form"
            method="get"
            className="modal-form-elements-wrap"
            data-wf-flow="27fd5a2379f3039044cbd1c5"
            data-wf-page-id="6661d364f032217548705cae"
            data-wf-element-id="fdb32211-aca9-5489-fb56-18fd9709764b"
          >
            <div
              id="w-node-fdb32211-aca9-5489-fb56-18fd9709764c-97097649"
              className="uui-max-width-xsmall"
            >
              <div
                id="w-node-fdb32211-aca9-5489-fb56-18fd9709764d-97097649"
                className="uui-text-align-center"
              >
                <div
                  id="w-node-fdb32211-aca9-5489-fb56-18fd9709764e-97097649"
                  className="web-heading-h4 web-heading-paragraph"
                >
                  Share your experience.
                </div>
                <div className="web-heading-paragraph web-body-small web-body-paragraph">
                  Write us your experience with Soul Trail and feature it on our
                  website. Let's people encourage explore!
                </div>
              </div>
            </div>
            <div
              id="w-node-fdb32211-aca9-5489-fb56-18fd97097652-97097649"
              className="modal-form-elements-row"
            >
              <div
                id="w-node-fdb32211-aca9-5489-fb56-18fd97097653-97097649"
                className="uui-form-field-wrapper"
              >
                <label htmlFor="Customer-Name-2" className="uui-field-label">
                  Name
                </label>
                <input
                  className="uui-form_input w-input"
                  maxLength={256}
                  name="Customer-Name-2"
                  data-name="Customer Name 2"
                  placeholder="Your full name"
                  type="text"
                  id="Customer-Name-2"
                  required=""
                />
              </div>
              <div
                id="w-node-fdb32211-aca9-5489-fb56-18fd97097657-97097649"
                className="uui-form-field-wrapper"
              >
                <label htmlFor="Email-Address-2" className="uui-field-label">
                  Email
                </label>
                <input
                  className="uui-form_input w-input"
                  maxLength={256}
                  name="Email-Address-2"
                  data-name="Email Address 2"
                  placeholder="example@email.com"
                  type="email"
                  id="Email-Address-2"
                  required=""
                />
              </div>
            </div>
            <div className="uui-form-field-wrapper">
              <label htmlFor="Review-2" className="uui-field-label">
                Review
              </label>
              <textarea
                className="uui-form_input text-area w-input"
                maxLength={400}
                name="Review-2"
                data-name="Review 2"
                placeholder="Type your review..."
                minLength={60}
                id="Review-2"
                required=""
                defaultValue={""}
              />
              <div className="web-body-small text-style-muted">
                Review size between 100 to 400 characters
              </div>
            </div>
            <div
              id="w-node-fdb32211-aca9-5489-fb56-18fd97097661-97097649"
              className="uui-form-button-wrapper"
            >
              <a
                id="w-node-fdb32211-aca9-5489-fb56-18fd97097662-97097649"
                data-w-id="fdb32211-aca9-5489-fb56-18fd97097662"
                href="#"
                className="uui-button-secondary-gray w-button"
              >
                Close
              </a>
              <input
                type="submit"
                data-wait="Please wait..."
                id="w-node-fdb32211-aca9-5489-fb56-18fd97097664-97097649"
                className="uui-button w-button"
                defaultValue="Submit"
              />
            </div>
          </form>
          <div className="success-message w-form-done">
            <div className="success-text">
              Thank you! Your submission has been received!
            </div>
          </div>
          <div className="error-message w-form-fail">
            <div className="error-text">
              Oops! Something went wrong while submitting the form.
            </div>
          </div>
        </div>
        <div
          data-w-id="fdb32211-aca9-5489-fb56-18fd9709766b"
          className="modal-overlay"
        />
      </div>
    </>
  )
}

export default About