import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StateContent from './StateContent';
import '../styling/App.css';
import '../styling/Index.css';
import '../styling/Style.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StateDetails = () => {
  const { t } = useTranslation();

  const { stateName } = useParams();
  const [content, setContent] = useState(null);
  const [weatherData, setWeatherData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const openModal = (event, url) => {
    event.preventDefault();
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setVideoUrl('');
    setIsModalOpen(false);
  };

  const handleStateSelect = (state) => {
    setIsOpen(false);
    navigate(`/state/${state}`);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && event.target === modalRef.current) {
        closeModal();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (stateName && StateContent[stateName]) {
      setContent(StateContent[stateName]);
    } else {
      setContent(null);
    }
  }, [stateName]);

  useEffect(() => {
    const fetchWeatherData = async (city) => {
      try {
        const apiKey = 'c9946468a66444b585f73021242908';
        const apiUrl = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${city}&aqi=no`;

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.location.country === 'India') {
          return { temp_c: `${data.current.temp_c}℃` };
        } else {
          throw new Error('City not found in India');
        }
      } catch (error) {
        return { temp_c: '' };
      }
    };

    const updateWeather = async () => {
      const weatherUpdates = {};
      if (content && content.cities) {
        for (const city of content.cities) {
          weatherUpdates[city] = await fetchWeatherData(city);
        }
        setWeatherData(weatherUpdates);
      }
    };

    if (content) {
      updateWeather();
    }
  }, [content]);

  if (content === null) {
    return <h2></h2>;
  }

  return (
    <>
      <Navbar />
      <div className="page-wrapper">
        <header className="uui-section_heroheader08">
          <div className="uui-page-padding">
            <div className="uui-container-large">
              <div className="uui-padding-vertical-xlarge">
                <div className="ani-slide-in-parent">
                  <div className="w-layout-grid uui-heroheader08_component">
                    <div className="uui-heroheader08_content">
                      <div className="ani-slide-in-on-parent is-child-01 w-dyn-item">
                        <h1 className="web-heading-h1 web-heading-paragraph">{content.title}</h1>
                      </div>
                      <div className="uui-max-width-small">
                        <div className="ani-slide-in-on-parent is-child-01 w-dyn-item">
                          <div className="event_cms_weather_collection w-dyn-list">
                            <div role="list" className="event_cms_weather_list-wrapper">
                              {content.cities.map((city) => (
                                <div key={city} role="listitem" className="event_cms_weather_badge w-dyn-item">
                                  <div className="web-body-regular city-name">{city}</div>
                                  <div className="web-body-regular weather-text temperature">
                                    {weatherData[city]?.temp_c || ''}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="uui-space-small" />
                        <div className="ani-slide-in-on-parent is-child-03">
                          <div className="w-layout-hflex event_cms_filters-wrapper">
                            <div className="event_dropdown_wrapper w-dropdown">
                              <div className="uui-form_input w-dropdown-toggle" onClick={toggleDropdown}>
                                <div className="icon w-icon-dropdown-toggle" />
                                <div className="web-body-regular">Select State</div>
                              </div>
                              {isOpen && (
                                <nav className="dropdown-list">
                                  <div className="w-dyn-list">
                                    <div role="list">
                                      <div role="listitem">
                                        <Link to='/packages' className="event_dropdown_item w-inline-block">
                                          All Events
                                        </Link>
                                      </div>
                                      {Object.keys(StateContent).map((state) => (
                                        <div role="listitem" key={state}>

                                          <div onClick={() => handleStateSelect(state)} className="event_dropdown_item w-inline-block">
                                            {StateContent[state].title}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </nav>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_0b15042a-5005-3c7d-29f2-e55ab2f40673-554978a2" className="ani-slide-in-on-parent packages is-child-01 w-dyn-item ">
                      <div className="uui-heroheader08_image-wrapper">
                        <img src={content.image} loading="lazy" alt="Header image" className="uui-heroheader08_image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section>
          <div className="uui-page-padding">
            <div className="uui-container-large">
              <div className="uui-padding-vertical-xlarge">
                <h5 className="web-heading-h3" style={{ marginBottom: '20px' }}>
                  {t('Packages.state')} {content.title} {t('Packages.state-text')}
                </h5>
                <div className="ani-slide-in-parent w-dyn-list">
                  <div role="list" className="event_cms_list-wrapper">
                    {content.videos.map((video, index) => (
                      <div
                        key={index}
                        role="listitem"
                        className="ani-slide-in-on-parent is-child-01 w-dyn-item"
                        onClick={(event) => openModal(event, video)}
                      >
                        <div className="event_cms_item-wrapper" >
                          
                          <img src={content.imgs[index]} alt={`Thumbnail ${index + 1}`} className="event_cms_image" />
                          
                        </div>
                      </div>
                    ))}
                  </div>
                  <div id="w-node-b842ff2f-41c4-07f1-cb3b-2651d7794d8f-554978a2" class="uui-navbar02_button-wrapper enquire_btn">
                    <Link to="/enquire" class="uui-button-2 w-inline-block">
                      <div>{t('Home.button2')}</div>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {isModalOpen && (
          <div ref={modalRef} className="video-modal" style={{ display: isModalOpen ? 'block' : 'none' }}>
            <div className="video-modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div className="video-container">
                <iframe
                  className="embedly-embed"
                  width={854}
                  height={480}
                  src={videoUrl}
                  title="YouTube Video"
                  allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                  allowFullScreen
                  style={{ border: 'none', outline: 'none' }}
                />
              </div>
            </div>
          </div>
        )}

      </div>
      <Footer />
    </>
  );
};

export default StateDetails;
